import { EventEmitter } from 'events';
import { Client, ActivationState } from '@stomp/stompjs';

const daqGatewayClient = () => import('@morgansolar/daq-gateway-client');

let Vue;

const MAX_RECONNECT_ATTEMPTS = 5;

class BrokerPlugin extends EventEmitter {
  constructor(kauth) {
    super();
    this.kauth = kauth;
    this.connectionRetries = 0;
    this.subscriptions = [];
    this.gatewayClients = {};

    this.client = new Client({
      brokerURL: process.env.VUE_APP_BROKER_URI,
      reconnectDelay: 200
    });

    this.client.beforeConnect = this.beforeConnect.bind(this);
    this.client.onConnect = this.onConnect.bind(this);
    this.client.onDisconnect = this.onDisconnect.bind(this);
    this.client.onWebSocketError = this.onError.bind(this);
    this.client.onStompError = this.onError.bind(this);
  }

  async connect() {
    if (this.client.state === ActivationState.ACTIVE) return;
    const token = await this.kauth.getValidToken();
    this.client.connectHeaders = { login: '#token', passcode: token };
    this.client.activate();
  }

  async disconnect() {
    this.connectionRetries = 0;
    return this.client.deactivate();
  }

  async beforeConnect() {
    const token = await this.kauth.getValidToken();
    this.client.connectHeaders = { login: '#token', passcode: token };
    this.connectionRetries++;
    if (this.connectionRetries > MAX_RECONNECT_ATTEMPTS) {
      this.emit('disconnected');
      this.client.deactivate();
    }
  }

  onConnect() {
    this.connectionRetries = 0;
    this.emit('connected');

    this.subscriptions.forEach(([destination, callback, headers]) => {
      const sub = this.client.subscribe(destination, callback, headers);
      if (!headers.id) headers.id = sub.id;
    });
  }

  onDisconnect() {
    this.emit('disconnected');
  }

  onError(err) {
    this.emit('broker-error', err);
  }

  onMessage(msg) {
    msg.ack();
    this.emit('message', msg);
  }

  subscribe(destination, callback, headers = {}) {
    if (this.client.connected) {
      const sub = this.client.subscribe(destination, callback, headers);
      headers.id = sub.id;
    }

    this.subscriptions.push([destination, callback, headers]);
  }

  publish(pubObj) {
    this.client.publish(pubObj);
  }

  async buildGatewayClient(gateway) {
    const [{ buildDaqGatewayBrokerClient }] = await Promise.all([daqGatewayClient(), this.kauth.getValidToken()]);
    const gatewayClient = buildDaqGatewayBrokerClient({
      brokerClient: this,
      gatewaySite: gateway.siteCode,
      gatewayId: gateway.code,
      userName: this.kauth.tokenParsed.preferred_username
    });

    return gatewayClient;
  }

  async getGatewayClient(gateway) {
    if (!this.gatewayClients[gateway.code]) {
      this.gatewayClients[gateway.code] = this.buildGatewayClient(gateway);
    }

    return this.gatewayClients[gateway.code];
  }

  install(_Vue) {
    if (Vue && _Vue === Vue) {
      if (process.env.NODE_ENV !== 'production') console.error('[broker] already installed. Vue.use(MessageBroker) should be called only once.');
      return;
    }

    Vue = _Vue;
    Vue.prototype.$broker = this;
  }
}

export default BrokerPlugin;
