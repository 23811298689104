/* eslint-disable */
import { make } from 'vuex-pathify';
import cloneDeep from 'lodash/cloneDeep';

const state = {
  initialized: false,
  settings: {
    rawData: true
  }
};


const mutations = make.mutations(state);
const getters = make.getters(state);

const actions = {
  async getSettings({ state, dispatch, commit }) {
    if (!state.initialized) {
      const savedSettings = await dispatch('localstorage/getFromLocalStorage', { key: 'settings' }, { root: true });
      if (savedSettings) {
        commit('SET_SETTINGS', {...state.settings, ...savedSettings });
      }
      commit('SET_INITIALIZED', true);
    }
    return cloneDeep(state.settings);
  },
  saveSettings({ dispatch, commit }, settings) {
    dispatch('localstorage/saveToLocalStorage', { key: 'settings', value: settings }, { root: true });
    commit('SET_SETTINGS', settings);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
