import { make } from 'vuex-pathify';
import { naturalCompare } from '../../helpers/helpers';

const state = {
  metricTypes: []
};

const getters = {
  getMetricTypeByCode: state => code => state.metricTypes.find(metricType => metricType.code === code)
};

const mutations = make.mutations(state);

const actions = {
  async setMetricTypes({ commit }) {
    const metricTypes = await this.$daqApi.get('/metric-types');
    metricTypes.sort((a, b) => naturalCompare(a.name, b.name));
    commit('SET_METRIC_TYPES', metricTypes);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
