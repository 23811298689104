import { EventEmitter } from 'events';
import Timeout from './timeout';

const IdleMonitorState = {
  RUNNING: 0,
  STOPPED: 1
};

class IdleMonitor extends EventEmitter{
  constructor() {
    super();
    this.TIMEOUT = 300000;
    this._userIdle = false;
    this._state = IdleMonitorState.STOPPED;
    this._timeout = new Timeout(this.TIMEOUT, () => this.handleTimeout());
    this._handler = e => this.onUserAction(e);
  }

  start() {
    if (this._state === IdleMonitorState.STOPPED) {
      document.addEventListener('mousemove', this._handler);
      document.addEventListener('keypress', this._handler);
      document.addEventListener('mousedown', this._handler);
      document.addEventListener('touchstart', this._handler);
      this._timeout.start();
      this._state = IdleMonitorState.RUNNING;
    }
  }

  stop() {
    if (this._state === IdleMonitorState.RUNNING) {
      document.removeEventListener('mousemove', this._handler);
      document.removeEventListener('keypress', this._handler);
      document.removeEventListener('mousedown', this._handler);
      document.removeEventListener('touchstart', this._handler);
      this._timeout.cancel();
      this._state = IdleMonitorState.STOPPED;
    }
  }

  get userIdle() {
    return this.__userIdle;
  }

  onUserAction() {
    if (this._state === IdleMonitorState.RUNNING) {
      if (this._userIdle) {
        this._userIdle = false;
        this.emit('useractive');
      }
      this._timeout.reset();
    }
  }

  handleTimeout() {
    if (this._state === IdleMonitorState.RUNNING) {
      this._userIdle = true;
      this.emit('useridle');
    }
  }
}

export default IdleMonitor;
