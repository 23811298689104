import compare from 'natural-compare';
import moment from 'moment-timezone';

export const uuidGenerator = (username) => {
  const requiredNameLen = 5;
  const nameLen = username.length;
  const requiredChars = '.xxxxxxxxxxxxxx';
  let extraChars = '';
  if (nameLen < requiredNameLen) {
    const diff = requiredNameLen - nameLen;
    extraChars = 'x'.repeat(diff);
  }

  const totalChars = extraChars + requiredChars;

  return username.substring(0, requiredNameLen) + totalChars.replace(/[x]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = Math.random() * 16 | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
};

export const sortBy = (type) => {
  return (a, b) => {
    if (a[type] < b[type]) { return -1; }
    if (a[type] > b[type]) { return 1; }
    return 0;
  };
};

export const throttled = (fn) => {
  let ticking = false;
  return () => {
    if (!ticking) {
      ticking = true;
      window.requestAnimationFrame(() => {
        ticking = false;
        fn();
      });
    }
  };
};

export const naturalCompare = (a, b) => {
  return compare(a.toLowerCase(), b.toLowerCase());
};

export function extractOwnDefaultMetrics(defaultMetrics) {
  return Object.keys(defaultMetrics || {}).reduce((acc, cur) => {
    if (!defaultMetrics[cur].inheritedFrom) acc[cur] = defaultMetrics[cur].metricUuid;
    return acc;
  }, {});
}

export function getYearIntervals(from, to) {
  const dateIterator = moment(from).startOf('year');

  const years = [];
  while (dateIterator <= to) {
    let intervalFrom;
    if (from >= dateIterator) intervalFrom = moment(from);
    else intervalFrom = moment(dateIterator);

    let intervalTo;
    const end = moment(dateIterator).endOf('year');
    if (to <= end) intervalTo = moment(to);
    else intervalTo = end;

    years.push({ from: intervalFrom, to: intervalTo });
    dateIterator.add(1, 'year');
  }

  return years;
}

export function mapSyntheticData(intervalData, intervals, timezone) {
  return intervalData.map((data, index) => {
    const mapYear = intervals[index].from.year();

    if (Array.isArray(data)) {
      return data.map((d) => {
        const dCopy = { ...d };
        if (dCopy.timestamp) dCopy.timestamp = moment(dCopy.timestamp).tz(timezone).year(mapYear).toISOString(true);
        return dCopy;
      });
    }

    const dCopy = { ...data };
    if (dCopy.timestamp) dCopy.timestamp = moment(dCopy.timestamp).tz(timezone).year(mapYear).toISOString(true);
    return dCopy;
  }).flat();
}
