import { naturalCompare } from '../../helpers/helpers';

const state = {
  sensors: []
};

const getters = {
  sensors: (state, _getters, _rootState, rootGetters) => {
    return state.sensors.map((sensor) => {
      const sensorType = rootGetters['sensorTypes/getSensorTypeByUuid'](sensor.sensorTypeUuid);
      if (sensorType) return { ...sensor, dataTypes: sensorType.dataSpec };
      return sensor;
    });
  },
  getSiteSensors: (_state, _getters) => (siteCode, includeArchived = false) => {
    return _getters.sensors.filter(sensor => sensor.siteCode === siteCode && (includeArchived || !sensor.archived));
  },
  getSensorByUuid: (_state, _getters) => uuid => _getters.sensors.find(sensor => sensor.uuid === uuid)
};

const mutations = {
  SET_SENSORS(state, sensors) {
    state.sensors = sensors;
  }
};

const actions = {
  async setSensors({ commit, rootGetters }) {
    const query = { all: true };
    const sites = rootGetters['sites/getSites'];
    const sensors = (await Promise.all(sites.map(site => this.$daqApi.get(`/sites/${site.id}/sensors`, { query })))).flat();
    sensors.sort((a, b) => naturalCompare(a.name, b.name));
    commit('SET_SENSORS', sensors);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
