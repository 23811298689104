// Import fa icons here and return them in an array

import {
  faCaretRight,
  faChevronRight,
  faChevronLeft,
  faTimes,
  faPlus,
  faMinus,
  faSun,
  faCloudSun,
  faCloud,
  faCloudShowersHeavy,
  faSnowflake,
  faMoon,
  faCloudMoon,
  faWind,
  faSmog,
  faExclamationCircle,
  faWrench,
  faLock,
  faQuestionCircle,
  faExclamationTriangle,
  faTrashAlt,
  faInfoCircle,
  faHistory,
  faArchive,
  faLink,
  faUnlink,
  faTable,
  faBullhorn,
  faPaperclip,
  faCheckCircle,
  faUser,
  faBell,
  faCog
} from '@fortawesome/free-solid-svg-icons';
import {
  faCopy,
  faClipboard,
} from '@fortawesome/free-regular-svg-icons';

export default function getFontAwesomeIcons() {
  const icons = [
    faCaretRight,
    faChevronRight,
    faChevronLeft,
    faTimes,
    faPlus,
    faMinus,
    faSun,
    faCloudSun,
    faCloud,
    faCloudShowersHeavy,
    faSnowflake,
    faMoon,
    faCloudMoon,
    faWind,
    faSmog,
    faExclamationCircle,
    faWrench,
    faLock,
    faQuestionCircle,
    faCopy,
    faClipboard,
    faExclamationTriangle,
    faTrashAlt,
    faInfoCircle,
    faHistory,
    faArchive,
    faLink,
    faUnlink,
    faTable,
    faBullhorn,
    faPaperclip,
    faCheckCircle,
    faUser,
    faBell,
    faCog
  ];

  return icons;
}
