const state = {
  links: [],
};

const getters = {
  getSiteLinks: state => siteCode => state.links.filter(link => link.siteCode === siteCode),
  getLinkByDaqUuid: state => uuid => state.links.find(link => link.daq.uuid === uuid),
  getLinkByModuleUuid: state => uuid => state.links.find(link => link.module.uuid === uuid)
};

const mutations = {
  SET_LINKS(state, links) {
    state.links = links;
  },
};

const actions = {
  async setLinks({ commit, rootGetters }) {
    const sites = rootGetters['sites/getSites'];
    const links = (await Promise.all(sites.map(site => this.$daqApi.get(`/sites/${site.id}/links`)))).flat();
    commit('SET_LINKS', links);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
