import Vue from 'vue';
import { BVModalPlugin, BVToastPlugin, VBModalPlugin, VBTogglePlugin, VBTooltipPlugin } from 'bootstrap-vue';
import VueI18n from 'vue-i18n';
import VueGtag from 'vue-gtag';

// import fontawesome for vue
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import StackdriverErrorsPlugin from './plugins/stackdriverErrors';
import DaqApiPlugin from './plugins/daqApi';
import BrokerPlugin from './plugins/broker';
import feature from './plugins/feature';
import GtagPlugin from './plugins/gtag';
import ToastPlugin from './plugins/toast';

import App from './App.vue';
import store from './store';
import router from './router';

import enMessages from './i18n/en.json';
import esMessages from './i18n/es.json';

import getFontAwesomeIcons from './FontAwesomeIcons';

function initPlugins(kauth) {
  const stackdriverErrors = new StackdriverErrorsPlugin(kauth);
  stackdriverErrors.setup();
  Vue.use(stackdriverErrors);
  store.$stackdriverErrors = stackdriverErrors;

  const daqApi = new DaqApiPlugin('daqApi',
    process.env.VUE_APP_DAQ_API_BASE_URL, async (opt) => {
      const token = await kauth.getValidToken();
      const authHeaders = { Authorization: `Bearer ${token}` };
      opt.headers = { ...authHeaders, ...opt.headers };
      return opt;
    });

  const broker = new BrokerPlugin(kauth);
  broker.connect();

  const gtagPlugin = new GtagPlugin(kauth);
  gtagPlugin.setup();

  Vue.use(kauth);
  Vue.use(daqApi);
  Vue.use(broker);
  Vue.use(feature);
  Vue.use(gtagPlugin);
  Vue.use(BVModalPlugin);
  Vue.use(BVToastPlugin);
  Vue.use(VBModalPlugin);
  Vue.use(VBTogglePlugin);
  Vue.use(VBTooltipPlugin);
  Vue.use(VueI18n);
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID },
    enabled: process.env.NODE_ENV === 'production',
  }, router);
  Vue.use(ToastPlugin);
  store.$daqApi = daqApi;
  store.$broker = broker;
  store.$gtagPlugin = gtagPlugin;
}

function initGlobalComponents() {
  library.add(getFontAwesomeIcons());
  Vue.component('font-awesome-icon', FontAwesomeIcon);
}

async function initStoreData() {
  await store.set('sites/sites');
  store.set('solarmodules/solarModules');
  store.set('sensors/sensors');
  store.set('groups/groups');
  store.set('moduleDesigns/moduleDesigns');
  store.set('gateways/gateways');
  store.set('daqs/daqs');
  store.set('daqLinks/links');
  store.set('sensorTypes/sensorTypes');
  store.set('metrics/metrics');
  store.set('metricTypes/metricTypes');
}

export default function init(kauth) {
  Vue.config.performance = true;
  initPlugins(kauth);
  initGlobalComponents();
  initStoreData();

  Vue.mixin({
    methods: {
      getFullModuleName(solarModule) {
        const { name, linkedDaq } = solarModule;
        if (linkedDaq) return `${name} ${linkedDaq}`;
        return name;
      }
    }
  });

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    store,
    router,
    i18n: new VueI18n({
      locale: 'en', // set locale
      fallbackLocale: 'en',
      messages: {
        en: enMessages,
        es: esMessages
      }
    }),
    render(h) {
      return h(App);
    }
  });
}
