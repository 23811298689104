import { naturalCompare, extractOwnDefaultMetrics } from '../../helpers/helpers';

const state = {
  groups: []
};

const getters = {
  getSiteGroups: state => siteCode => state.groups.filter(group => group.siteCode === siteCode),
  getGroupByUuid: state => uuid => state.groups.find(group => group.uuid === uuid),
  getGroups: state => state.groups,
  getSiteGroupedModules: (state, _getters, _rootState, rootGetters) => (siteCode) => {
    const groups = {};
    const ungroupedModules = [];
    const modules = rootGetters['solarmodules/getSiteSolarModules'](siteCode);
    modules.forEach((module) => {
      if (!module.groupUuid) ungroupedModules.push(module);
      else {
        const group = _getters.getGroupByUuid(module.groupUuid);
        if (!group) ungroupedModules.push(module);
        else {
          if (!groups[module.groupUuid]) groups[module.groupUuid] = { group, modules: [] };
          groups[module.groupUuid].modules.push(module);
        }
      }
    });

    const groupedModules = Object.values(groups);
    if (ungroupedModules.length) groupedModules.push({ group: { name: 'Ungrouped' }, modules: ungroupedModules });
    groupedModules.sort((a, b) => naturalCompare(a.group.name, b.group.name));
    return groupedModules;
  }
};

const mutations = {
  SET_GROUPS(state, groups) {
    state.groups = groups;
  }
};

const actions = {
  async setGroups({ commit, rootGetters }) {
    const sites = rootGetters['sites/getSites'];
    const groups = (await Promise.all(sites.map(site => this.$daqApi.get(`/sites/${site.id}/groups`))))
      .flat()
      .map(g => ({ ...g, ownDefaultMetrics: extractOwnDefaultMetrics(g.defaultMetrics) }));

    groups.sort((a, b) => naturalCompare(a.name, b.name));
    commit('SET_GROUPS', groups);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
