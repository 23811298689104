import { naturalCompare, extractOwnDefaultMetrics } from '../../helpers/helpers';

const state = {
  solarModules: [],
  initialized: false
};

const getters = {
  solarModules: (state, _getters, _rootState, rootGetters) => {
    return state.solarModules.map((solarModule) => {
      const associations = { moduleDesign: { length: 0, width: 0 } };

      const link = rootGetters['daqLinks/getLinkByModuleUuid'](solarModule.uuid);
      const linkedDaq = link && rootGetters['daqs/getDaqByUuid'](link.daq.uuid);
      if (linkedDaq) associations.linkedDaq = linkedDaq.name;

      const moduleDesign = rootGetters['moduleDesigns/getModuleDesignByUuid'](solarModule.moduleDesignUuid);
      if (moduleDesign) associations.moduleDesign = moduleDesign;
      return { ...solarModule, ...associations };
    });
  },
  getSiteSolarModules: (_state, _getters) => (siteCode, includeArchived = false) => {
    const solarModules = _getters.solarModules.filter((solarModule) => {
      return solarModule.siteCode === siteCode && (includeArchived || !solarModule.archived);
    });

    return solarModules;
  },
  getSolarModuleByUuid: (_state, _getters) => uuid => _getters.solarModules.find(solarModule => solarModule.uuid === uuid),
  initialized: state => state.initialized,
};

const mutations = {
  SET_SOLAR_MODULES(state, solarModules) {
    state.solarModules = solarModules;
  },
  SET_INITIALIZED(state, initialized) {
    state.initialized = initialized;
  }
};

const actions = {
  async setSolarModules({ commit, rootGetters }) {
    const query = { all: true };
    const sites = rootGetters['sites/getSites'];
    const solarModules = (await Promise.all(sites.map(site => this.$daqApi.get(`/sites/${site.id}/modules`, { query }))))
      .flat()
      .map(m => ({ ...m, ownDefaultMetrics: extractOwnDefaultMetrics(m.defaultMetrics) }));

    solarModules.sort((a, b) => naturalCompare(a.name, b.name));
    commit('SET_SOLAR_MODULES', solarModules);
    commit('SET_INITIALIZED', true);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
