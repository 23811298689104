let Vue;

const types = {
  success: 'check-circle',
  info: 'info-circle',
  warning: 'exclamation-triangle',
  danger: 'exclamation-circle'
};

function _formatTitle(ctx, title, type) {
  return ctx.$createElement(
    'div',
    { class: ['d-flex', 'align-items-center'] },
    [
      ctx.$createElement('font-awesome-icon', { props: { icon: types[type], size: '1x', 'fixed-width': true } }),
      ctx.$createElement('div', { class: ['ml-1'], style: { 'margin-bottom': '1px', 'font-weight': '500' } }, [title])
    ]
  );
}

function success(title, message) {
  const type = 'success';
  const vNodeTitle = _formatTitle(this, title, type);
  this.$root.$bvToast.toast(message, { title: [vNodeTitle], variant: type });
}

function info(title, message) {
  const type = 'info';
  const vNodeTitle = _formatTitle(this, title, type);
  this.$root.$bvToast.toast(message, { title: [vNodeTitle], variant: type });
}

function warn(title, message) {
  const type = 'warning';
  const vNodeTitle = _formatTitle(this, title, type);
  this.$root.$bvToast.toast(message, { title: [vNodeTitle], variant: type });
}

function error(title = 'Error', message = 'An error has occurred.') {
  const type = 'danger';
  const vNodeTitle = _formatTitle(this, title, type);
  this.$root.$bvToast.toast(message, { title: [vNodeTitle], variant: type });
}

export default {
  install(_Vue) {
    if (Vue && _Vue === Vue) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(
          '[broker] already installed. Vue.use(MessageBroker) should be called only once.'
        );
      }
      return;
    }
    Vue = _Vue;
    Vue.prototype.$toastSuccess = success;
    Vue.prototype.$toastInfo = info;
    Vue.prototype.$toastWarn = warn;
    Vue.prototype.$toastError = error;
  }
};
