import { naturalCompare } from '../../helpers/helpers';

const state = {
  daqs: [],
};

const getters = {
  getSiteDaqs: state => siteCode => state.daqs.filter(daq => daq.siteCode === siteCode),
  getDaqByUuid: state => uuid => state.daqs.find(daq => daq.uuid === uuid)
};

const mutations = {
  SET_DAQS(state, daqs) {
    state.daqs = daqs;
  },
};

const actions = {
  async setDaqs({ commit, rootGetters }) {
    const sites = rootGetters['sites/getSites'];
    const daqs = (await Promise.all(sites.map(site => this.$daqApi.get(`/sites/${site.id}/daqs`)))).flat();
    daqs.sort((a, b) => naturalCompare(a.name, b.name));
    commit('SET_DAQS', daqs);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
