<template>
  <div class="feedback-form">
    <div class="pb-3 preface">
      Your feedback is important to us. Let us know what you think of the DAQ Portal and how we can improve.
    </div>

    <div class="pb-2 warning">
      * Please don't share any sensitive information in this form.
    </div>

    <b-form-select v-model="type" :options="typeOptions" size="sm" class="mb-3" :disabled="loading" />

    <b-input-group size="sm" class="mb-3">
      <b-form-input v-model="title" placeholder="Enter a title" :disabled="loading" />
    </b-input-group>

    <b-input-group size="sm" class="mb-3">
      <b-form-textarea v-model="body" placeholder="Enter details" rows="5" :disabled="loading" />
    </b-input-group>

    <div class="d-flex">
      <label class="btn btn-primary btn-sm attach-image mb-0" for="feedback-add-snapshot" :class="{ disabled: loading }">
        <input id="feedback-add-snapshot" type="file" class="d-none" :accept="['image/png', 'image/jpeg']" multiple @change="addSnapshot"
          :disabled="loading">
        <font-awesome-icon icon="paperclip" size="1x" />
        Attach Image
      </label>

      <div class="d-flex justify-content-end flex-grow-1 align-items-center">
        <msi-spinner v-if="loading" :size="24" />
        <b-button size="sm" variant="primary" class="ml-1" :disabled="!readyToSubmit || loading" @click="submit">Submit</b-button>
      </div>
    </div>

    <div v-if="Object.keys(files).length > 0" class="mt-3">
      <div class="snapshot-title pb-2">Snapshots</div>
      <div v-for="file in files" :key="file.value.name" class="d-flex align-items-center justify-content-between p-2 mb-2 snapshot-file">
        <div class="snapshot-name mr-2">{{ file.value.name }}</div>
        <b-progress class="snapshot-progress flex-grow-1" height="5px" :value="file.value.progress" variant="success"
          v-if="file.status === 'pending'" />
        <div class="d-flex flex-grow-1 justify-content-center snapshot-success" v-if="file.status === 'fulfilled'">SUCCESS</div>
        <div class="d-flex flex-grow-1 justify-content-center snapshot-failed" v-if="file.status === 'rejected'">FAILED</div>
        <font-awesome-icon icon="times" size="1x" fixed-width class="icon" @click="removeSnapshot(file.value.name)"></font-awesome-icon>
      </div>
      <div v-if="failedSnapshots" class="mt-1 failed-snapshots">
        Some of your uploads have failed. You can only upload images with the following formats: {{ acceptedFormats.join(', ') }}.
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
import { BFormSelect, BInputGroup, BFormInput, BFormTextarea, BButton, BProgress } from 'bootstrap-vue';
import { v1 as uuidv1 } from 'uuid';
import MsiSpinner from './MsiSpinner.vue';

export default {
  name: 'Feedback',
  components: {
    MsiSpinner,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BProgress
  },
  data() {
    return {
      title: '',
      body: '',
      type: null,
      typeOptions: [
        { text: 'Select a feedback type', value: null, disabled: true },
        { text: 'Feature Request', value: { label: 'Feature Request', value: 'enhancement' } },
        { text: 'Bug Report', value: { label: 'Bug Report', value: 'bug' } },
        { text: 'Comment', value: { label: 'Comment', value: 'comment' } }
      ],
      files: {},
      acceptedFormats: ['png', 'jpeg', 'jpg'],
      loading: false
    };
  },
  computed: {
    readyToSubmit() {
      return this.title.trim().length > 0 && this.type && this.completedSnapshots;
    },
    completedSnapshots() {
      const files = Object.values(this.files);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.status === 'pending') return false;
      }

      return true;
    },
    failedSnapshots() {
      const files = Object.values(this.files);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.status === 'rejected') return true;
      }

      return false;
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    async submit() {
      try {
        if (!this.readyToSubmit) return;
        this.loading = true;
        const snapshots = await this.uploadSnapshots();
        await this.createIssue(snapshots);
        this.$toastSuccess('Feedback Received', 'Thank you for the feedback.');
      } catch (e) {
        this.$toastError('Error', 'An error occurred while submitting your feedback. Please try again.');
      } finally {
        this.loading = false;
        this.handleClose();
      }
    },
    addSnapshot(event) {
      if (this.loading) return;

      Object.keys(this.files).forEach((key) => {
        if (this.files[key].status === 'rejected') this.$delete(this.files, key);
      });

      Array.from(event.target.files)
        .filter(file => !this.files[file.name])
        .map(async (file) => {
          const { name } = file;
          const validSnapshot = await this.checkIfValidSnapshot(file);
          if (!validSnapshot) {
            this.$set(this.files, name, { status: 'rejected', value: { name } });
          } else {
            const reader = new FileReader();
            reader.addEventListener('progress', (e) => {
              this.$set(this.files, name, { status: 'pending', value: { name, progress: (e.loaded / e.total) * 100 } });
            });

            reader.addEventListener('load', (e) => {
              const content = e.target.result.split(',')[1];
              if (!content) this.$set(this.files, name, { status: 'rejected', value: { name } });
              else this.$set(this.files, name, { status: 'fulfilled', value: { name, content, progress: 100 } });
            });

            reader.addEventListener('error', () => {
              this.$set(this.files, name, { status: 'rejected', value: { name } });
            });

            reader.readAsDataURL(file);
          }
        });
    },
    removeSnapshot(key) {
      this.$delete(this.files, key);
    },
    checkIfValidSnapshot(file) {
      return new Promise((resolve) => {
        const extension = file.name.split('.')[1].toLowerCase();
        if (!this.acceptedFormats.includes(extension)) {
          resolve(false);
          return;
        }

        const snapshot = new Image();
        snapshot.addEventListener('load', () => resolve(true));
        snapshot.addEventListener('error', () => resolve(false));
        snapshot.src = URL.createObjectURL(file);
      });
    },
    async uploadSnapshots() {
      const responses = [];
      const files = Object.values(this.files);
      for (let i = 0; i < files.length; i++) {
        const { status, value: file } = files[i];
        if (status !== 'fulfilled') continue;

        const name = uuidv1();
        const extension = file.name.split('.')[1].toLowerCase();
        const res = await fetch(`${process.env.VUE_APP_GITHUB_ISSUES}/contents/snapshots/${name}.${extension}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.$kauth.idTokenParsed.github_token}`,
            Accept: 'application/vnd.github.v3+json'
          },
          body: JSON.stringify({
            message: `Added resource ${name}.${extension}`,
            content: file.content
          })
        });

        if (!res.ok) {
          const error = await res.text();
          throw new Error(error);
        }

        responses.push(res.json());
      }

      return Promise.all(responses);
    },
    async createIssue(snapshots) {
      let body = `Type: ${this.type.label}\n\n`;
      if (this.body) body += `${this.body}\n\n`;
      body += `User: ${this.$kauth.idTokenParsed.sub}\n\n`;
      snapshots.forEach((r) => {
        body += `![${r.content.name}](${r.content.html_url}?raw=true)\n`;
      });

      const res = await fetch(`${process.env.VUE_APP_GITHUB_ISSUES}/issues`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.$kauth.idTokenParsed.github_token}`,
          Accept: 'application/vnd.github.v3+json'
        },
        body: JSON.stringify({
          title: this.title,
          body,
          labels: [this.type.value]
        })
      });

      if (res.ok) return res.json();
      const error = await res.text();
      throw new Error(error);
    }
  }
};
</script>

<style lang="scss">
.feedback-form {
  font-size: 0.8rem;
}

.icon {
  color: $msi-gray-400;
  cursor: pointer;
}

.icon:hover {
  color: $msi-gray-600;
}

.preface {
  font-weight: 500;
  font-size: 0.85rem;
}

.warning {
  font-weight: 500;
  font-size: 0.8rem;
}

.snapshot-title {
  font-size: 1rem;
  font-weight: 500;
}

.snapshot-file {
  background-color: $msi-gray-100;
}

.snapshot-name {
  word-break: break-word;
  flex-basis: 50%;
}

.snapshot-progress {
  max-width: 200px;
}

.snapshot-success {
  font-size: 0.7rem;
  font-weight: 500;
  color: $msi-success;
  max-width: 200px;
}

.snapshot-failed {
  font-size: 0.7rem;
  font-weight: 500;
  color: $msi-danger;
  max-width: 200px;
}

.failed-snapshots {
  color: $msi-danger;
  font-weight: 500;
}

.attach-image {
  cursor: pointer;
}
</style>
