import { naturalCompare } from '../../helpers/helpers';

const state = {
  moduleDesigns: [],
  templateModuleDesigns: []
};

const getters = {
  getSiteModuleDesigns: state => siteCode => state.moduleDesigns.filter(design => design.siteCode === siteCode),
  getModuleDesignByUuid: state => uuid => state.moduleDesigns.find(design => design.uuid === uuid)
};

const mutations = {
  SET_MODULE_DESIGNS(state, designs) {
    state.moduleDesigns = designs;
  },
  SET_TEMPLATE_MODULE_DESIGNS(state, designs) {
    state.templateModuleDesigns = designs;
  }
};

const actions = {
  async setModuleDesigns({ commit, rootGetters }) {
    const sites = rootGetters['sites/getSites'];
    const designs = (await Promise.all(sites.map(site => this.$daqApi.get(`/sites/${site.id}/module-designs`)))).flat();
    designs.sort((a, b) => naturalCompare(a.name, b.name));
    commit('SET_MODULE_DESIGNS', designs);
  },
  async setTemplateModuleDesigns({ commit }) {
    const designs = await this.$daqApi.get('/template-module-designs');
    designs.sort((a, b) => naturalCompare(a.manufacturer, b.manufacturer) || naturalCompare(a.modelNumber, b.modelNumber));
    commit('SET_TEMPLATE_MODULE_DESIGNS', designs);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
