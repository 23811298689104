import Vue from 'vue';
import { naturalCompare } from '../../helpers/helpers';

const state = {
  gateways: [],
  gatewayStatus: {}
};

const getters = {
  getSiteGateways: state => siteCode => state.gateways.filter(gateway => gateway.siteCode === siteCode),
  getGatewayStatus: state => code => state.gatewayStatus[code],
  getGatewayByUuid: state => uuid => state.gateways.find(gateway => gateway.uuid === uuid)
};

const mutations = {
  SET_GATEWAYS(state, gateways) {
    state.gateways = gateways;
  },
  SET_GATEWAY_STATUS(state, { code, status }) {
    Vue.set(state.gatewayStatus, code, status);
  }
};

const actions = {
  async setGateways({ commit, dispatch, rootGetters }) {
    const sites = rootGetters['sites/getSites'];
    const gateways = (await Promise.all(sites.map(site => this.$daqApi.get(`/sites/${site.id}/gateways`)))).flat();
    gateways.sort((a, b) => naturalCompare(a.name, b.name));
    commit('SET_GATEWAYS', gateways);
    dispatch('monitorGatewayConnection');
  },

  async monitorGatewayConnection({ state, commit }) {
    const { gateways } = state;
    let timeoutId = null;

    gateways.forEach(gateway => commit('SET_GATEWAY_STATUS', { code: gateway.code, status: 'PENDING' }));
    timeoutId = setTimeout(() => {
      gateways.forEach((gateway) => {
        if (state.gatewayStatus[gateway.code] === 'PENDING') commit('SET_GATEWAY_STATUS', { code: gateway.code, status: 'OFFLINE' });
      });
    }, process.env.VUE_APP_BROKER_TIMEOUT);

    gateways.forEach((gateway) => {
      this.$broker.subscribe(`/topic/ActiveMQ.Advisory.Consumer.Queue.msi.meerkat.Gateway.${gateway.siteCode}.${gateway.code}`, (msg) => {
        if (msg.headers.consumerCount > 0) {
          commit('SET_GATEWAY_STATUS', { code: gateway.code, status: 'ONLINE' });
          if (msg.headers.consumerCount > 1) {
            this.$stackdriverErrors.report(`${gateway.code} has ${msg.headers.consumerCount} consumers`);
          }
        } else commit('SET_GATEWAY_STATUS', { code: gateway.code, status: 'OFFLINE' });
      });
    });

    this.$broker.on('connected', () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }

      gateways.forEach(gateway => commit('SET_GATEWAY_STATUS', { code: gateway.code, status: 'PENDING' }));
      timeoutId = setTimeout(() => {
        gateways.forEach((gateway) => {
          if (state.gatewayStatus[gateway.code] === 'PENDING') commit('SET_GATEWAY_STATUS', { code: gateway.code, status: 'OFFLINE' });
        });
      }, process.env.VUE_APP_BROKER_TIMEOUT);
    });

    this.$broker.on('disconnected', () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }

      gateways.forEach(gateway => commit('SET_GATEWAY_STATUS', { code: gateway.code, status: 'SERVER DISCONNECTED' }));
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
