/**
 * Contains all actions to map to event functions in the GtagPlugin class.
 * Format:
 * { <category>: [...<actions>] }
 */

export default {
  Site: [
    'select_site'
  ],
  Analysis: [
    'new_graph'
  ],
  DAQ: [
    'new',
    'edit',
    'link',
    'unlink',
  ],
  Sensor: [
    'new',
    'edit',
    'archive',
    'delete',
  ],
  Module: [
    'new',
    'edit',
    'archive',
    'delete',
  ],
  ModuleDesign: [
    'new',
    'edit',
    'delete',
  ],
  ModuleGroup: [
    'new',
    'edit',
    'delete',
  ],
};
