import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

import localstorage from './modules/localstorage.module';
import sites from './modules/sites.module';
import sensors from './modules/sensors.module';
import moduleDesigns from './modules/moduleDesigns.module';
import groups from './modules/groups.module';
import gateways from './modules/gateways.module';
import daqs from './modules/daqs.module';
import daqLinks from './modules/daqLinks.module';
import solarmodules from './modules/solarmodules.module';
import settings from './modules/settings.module';
import sensorTypes from './modules/sensorTypes.module';
import metrics from './modules/metrics.module';
import metricTypes from './modules/metricTypes.module';

import idleMonitorPlugin from './plugins/idleMonitor';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state: {
    userIdle: false
  },
  mutations: {
    setUserIdle(state, userIdle) {
      state.userIdle = userIdle;
      if (userIdle) this.$broker.disconnect();
      else this.$broker.connect();
    }
  },
  modules: {
    localstorage,
    sites,
    sensors,
    groups,
    gateways,
    daqs,
    daqLinks,
    solarmodules,
    settings,
    moduleDesigns,
    sensorTypes,
    metrics,
    metricTypes
  },
  strict: debug,
  plugins: [idleMonitorPlugin, pathify.plugin]
});
