import Feature from './feature';

let Vue;
export default {
  install(_Vue) {
    if (Vue && _Vue === Vue) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(
          '[feature] already installed. Vue.use(feature) should be called only once.'
        );
      }
      return;
    }
    Vue = _Vue;
    if (process.env.NODE_ENV === 'development') {
      Object.defineProperty(Vue.prototype, '$feature', {
        get: () => Feature()
      });
    } else {
      Vue.prototype.$feature = Feature();
    }
  }
};
