<template>
  <b-nav-item-dropdown right no-caret class="msi-dropdown">
    <template slot="button-content">
      <i class="icon-user icons font-2xl"></i>
    </template>

    <b-dropdown-header tag="div"><strong>Account</strong></b-dropdown-header>
    <b-dropdown-item href="https://accounts.morgansolar.com/auth/realms/meerkat/account/" target="_blank">
      <font-awesome-icon class="dropdown-icon mr-2" icon="user"/>Profile
    </b-dropdown-item>
    <b-dropdown-item @click="handleSettings">
      <font-awesome-icon class="dropdown-icon mr-2" icon="wrench"/>Settings
    </b-dropdown-item>
    <b-dropdown-item @click="handleLogout">
      <font-awesome-icon class="dropdown-icon mr-2" icon="lock"/>Logout
    </b-dropdown-item>

    <b-dropdown-header tag="div"><strong>Site</strong></b-dropdown-header>
    <b-dropdown-item @click="handleAlerts">
      <font-awesome-icon class="dropdown-icon mr-2" icon="bell"/>Alerts
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BDropdownHeader, BDropdownItem, BNavItemDropdown } from 'bootstrap-vue';

export default {
  name: 'MSIHeaderDropdownAccnt',
  components: {
    BNavItemDropdown,
    BDropdownHeader,
    BDropdownItem
  },
  data: () => ({}),
  methods: {
    handleLogout() {
      this.$kauth.logout();
    },
    handleSettings() {
      this.$emit('settings');
    },
    handleAlerts() {
      this.$emit('alerts');
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-icon {
  color: rgba(32, 32, 32, 0.616);
}

.dropdown-item:active .dropdown-icon {
  color: $msi-white;
}
</style>
