<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      shownModals: new Set()
    };
  },
  mounted() {
    // Close modals on navigate back/forward
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => this.shownModals.add(modalId));
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => this.shownModals.delete(modalId));
    this.$router.beforeEach((to, from, next) => {
      if (this.shownModals.size) {
        this.shownModals.forEach(modalId => this.$bvModal.hide(modalId));
        // If modal is open and back is pressed on mobile, close modal and don't navigate.
        if (this.$feature.mobile) {
          if (to && to.name === 'Analysis' && to.query && to.query.graph) return next();
          return next(false);
        }
      }
      return next();
    });
  }
};
</script>

<style lang="scss">
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  @import 'assets/scss/style';
  @import '~vue-multiselect/dist/vue-multiselect.min.css';
  @import '~flatpickr/dist/flatpickr.css';
  @import '~flatpickr/dist/plugins/monthSelect/style.css';
</style>
