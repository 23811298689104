<template>
  <div>
    <div class="pb-3">
      <h6>Alerts for {{ selectedSite.name }}</h6>
      <div>
        Subscribing to an alert will send you an email when the event that triggers the alert occurs.
      </div>
    </div>

    <div class="d-flex justify-content-center" v-if="getAlertsLoading"><msi-spinner /></div>

    <div v-for="alert in alerts" :key="alert.subId">
      <b-form-group
        :label="alert.name"
        :description="alert.description">
        <b-form-checkbox
          v-model="subscribed[alert.subId]"
          switch
          :disabled="changeAlertsLoading">
          {{ subscribed[alert.subId] ? 'ON' : 'OFF' }}
        </b-form-checkbox>
      </b-form-group>
      </div>

    <div class="d-flex justify-content-end">
      <msi-spinner :size="30" v-if="changeAlertsLoading" />
      <b-button variant="primary" class="ml-2" size="sm" @click="handleSave" :disabled="changeAlertsLoading">Save</b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { BFormGroup, BFormCheckbox, BButton } from 'bootstrap-vue';
import { get } from 'vuex-pathify';
import MsiSpinner from './MsiSpinner.vue';

function wordsJoiner(words) {
  if (words.length === 0 || words.length === 1) return words.join();
  if (words.length === 2) return words.join(' and ');
  if (words.length > 2) {
    words[words.length - 1] = `and ${words[words.length - 1]}`;
    return words.join(', ');
  }

  return '';
}

export default {
  name: 'Settings',
  components: {
    BFormGroup,
    BFormCheckbox,
    BButton,
    MsiSpinner
  },
  data() {
    return {
      alerts: [],
      subscribed: {},
      getAlertsLoading: false,
      changeAlertsLoading: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite')
  },
  methods: {
    notifyUser(responses, success) {
      if (!responses.length) return;
      const subscribed = responses.filter(({ alert }) => this.subscribed[alert.subId]);
      const unsubscribed = responses.filter(({ alert }) => !this.subscribed[alert.subId]);
      const subMsg = subscribed.length ? `${wordsJoiner(subscribed.map(({ alert }) => alert.name))} ${subscribed.length === 1 ? 'alert' : 'alerts'}. ` : '';
      const unsubMsg = unsubscribed.length ? `${wordsJoiner(unsubscribed.map(({ alert }) => alert.name))} ${unsubscribed.length === 1 ? 'alert' : 'alerts'}.` : '';

      if (success) {
        this.$toastSuccess('Success', `${subMsg ? 'You have subscribed to the ' : ''}${subMsg}${unsubMsg ? 'You have unsubscribed from the ' : ''}${unsubMsg}`);
      } else {
        this.$toastError('Error', `${subMsg ? 'Failed to subscribe to the ' : ''}${subMsg}${unsubMsg ? 'Failed to unsubscribe from the ' : ''}${unsubMsg}`);
      }
    },
    async handleSave() {
      const changedSubscriptions = this.alerts.filter(alert => alert.subscribed !== this.subscribed[alert.subId]);
      if (!changedSubscriptions.length) return;

      this.changeAlertsLoading = true;
      const promises = await Promise.allSettled(changedSubscriptions.map(({ subId }) => {
        const type = this.subscribed[subId] ? 'subscribe' : 'unsubscribe';
        return this.$daqApi.post(`/sites/${this.selectedSite.id}/user/alerts/${type}`, { query: { subId } });
      }));

      const responses = promises.map((promise, index) => ({ promise, alert: changedSubscriptions[index] }));
      const successResponses = responses.filter(response => response.promise.status === 'fulfilled');
      this.notifyUser(successResponses, true);
      const failedResponses = responses.filter(response => response.promise.status === 'rejected');
      this.notifyUser(failedResponses, false);

      try {
        this.alerts = await this.$daqApi.get(`/sites/${this.selectedSite.id}/user/alerts`);
        this.alerts.forEach(alert => this.$set(this.subscribed, alert.subId, alert.subscribed));
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.changeAlertsLoading = false;
      }
    },
    async getAlerts() {
      try {
        this.getAlertsLoading = true;
        this.alerts = await this.$daqApi.get(`/sites/${this.selectedSite.id}/user/alerts`);
        this.alerts.forEach(alert => this.$set(this.subscribed, alert.subId, alert.subscribed));
      } catch (e) {
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.getAlertsLoading = false;
      }
    }
  },
  mounted() {
    this.getAlerts();
  }
};
</script>
