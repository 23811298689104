class Timeout {
  constructor(duration, callback) {
    this._id = null;
    this._duration = duration;
    this._callback = callback;
  }

  start() {
    if (this._duration == null || this._callback == null){
      throw new Error('Cannot start timout without a duration or a callback');
    }
    this._id = setTimeout(() => this._done(), this._duration);
  }

  cancel() {
    if (this._id != null){
      clearTimeout(this._id);
      this._id = null;
    }
  }

  reset() {
    this.cancel();
    this.start();
  }

  _done() {
    this._id = null;
    this._callback();
  }

  set callback(callback) {
    this._callback = callback;
  }

  set duration(duration) {
    this._duration = duration;
  }

  get isActive() {
    return this._id == null;
  }
}

export default Timeout;
