/* eslint-disable linebreak-style */

const actions = {
  saveToLocalStorage(context, store) {
    window.localStorage.setItem(store.key, JSON.stringify(store.value));
  },
  getFromLocalStorage(context, store) {
    const data = window.localStorage.getItem(store.key);
    if (data) {
      return JSON.parse(data);
    }

    return null;
  },
  removeFromLocalStorage(context, store) {
    window.localStorage.removeItem(store.key);
  }
};

export default {
  namespaced: true,
  actions
};
