import qs from 'querystring';
import 'whatwg-fetch';
import AbortControllerPolyfill from 'abortcontroller-polyfill/src/abortcontroller';
import { polyfillNeeded as abortPolyfillNeeded } from 'abortcontroller-polyfill/src/utils';
import { ReadableWebToNodeStream } from 'readable-web-to-node-stream';
import JSONStream from 'JSONStream';
import moment from 'moment-timezone';
import store from '../store';
import { getYearIntervals, mapSyntheticData } from '../helpers/helpers';

let Vue;

const AbortController = abortPolyfillNeeded(window) ? AbortControllerPolyfill : window.AbortController;

class DaqApiPlugin {
  constructor(name, baseUrl, requestIntercept = opt => opt) {
    this.name = name;
    this.baseUrl = baseUrl;
    this.requestIntercept = requestIntercept;
    this.AbortController = AbortController;
  }

  async request(method, path, options = {}) {
    options = await this.requestIntercept(options);
    const queryStr = options.query ? `?${qs.stringify(options.query)}` : '';
    const url = `${this.baseUrl}${path}${queryStr}`;
    options.method = method;
    if (options.body && (method !== 'GET' || method !== 'HEAD')) {
      options.headers['Content-Type'] = 'application/json; charset=utf-8';
      options.body = JSON.stringify(options.body);
    }

    return fetch(url, options);
  }

  async syntheticRequest(method, path, options) {
    const timezone = 'America/Denver';
    const now = moment().tz(timezone);
    const from = moment(options.query.from).tz(timezone);
    let to = moment(options.query.to).tz(timezone);
    if (to > now) to = now;
    const intervals = getYearIntervals(from, to);
    const responses = await Promise.all(intervals.map(async (interval) => {
      const optionsCopy = { ...options };
      optionsCopy.query = {
        ...optionsCopy.query,
        from: moment(interval.from).year(2022).toISOString(true),
        to: moment(interval.to).year(2022).toISOString(true)
      };

      return this.request(method, path, optionsCopy);
    }));

    const responseBodies = await Promise.all(responses.map(r => r.json()));

    const errIndex = responses.findIndex(r => r.status < 200 || r.status > 299);
    if (errIndex > -1) {
      return { response: responses[errIndex], body: responseBodies[errIndex] };
    }

    return { response: responses[0], body: mapSyntheticData(responseBodies, intervals, timezone) };
  }

  async requestHandler(method, path, options, includeHeaders = false) {
    let response;
    let body;
    if (method === 'GET' && path.includes('/sites/D-MSD') && options && options.query && options.query.from && options.query.to) {
      ({ response, body } = await this.syntheticRequest(method, path, options));
    } else {
      response = await this.request(method, path, options);
      body = await response.json();
    }

    if (response.status >= 200 && response.status <= 299) {
      if (includeHeaders) return { headers: response.headers, body };
      return body;
    }

    const apiError = new Error();
    apiError.name = 'ApiError';
    apiError.message = body.message || 'Error';
    apiError.status = response.status;
    apiError.responseBody = body;
    apiError.request = { method, path, options };
    throw apiError;
  }

  async get(path, options, includeHeaders) {
    return this.requestHandler('GET', path, options, includeHeaders);
  }

  async post(path, options) {
    return this.requestHandler('POST', path, options);
  }

  async put(path, options) {
    return this.requestHandler('PUT', path, options);
  }

  async delete(path, options) {
    return this.requestHandler('DELETE', path, options);
  }

  async requestStream(method, path, options) {
    let response;
    let body;
    if (method === 'GET' && path.includes('/sites/D-MSD') && options && options.query && options.query.from && options.query.to) {
      ({ response, body } = await this.syntheticRequest(method, path, options));
      if (response.status >= 200 && response.status <= 299) {
        let i = 0;
        const stream = new ReadableStream({
          pull(controller) {
            if (i === 0) controller.enqueue('[');
            const data = body[i];
            if (data) {
              controller.enqueue(JSON.stringify(data));
              if (i < body.length - 1) controller.enqueue(',');
              i++;
            } else {
              controller.enqueue(']');
              controller.close();
            }
          }
        });

        const nodeStream = new ReadableWebToNodeStream(stream);
        const jsonStream = JSONStream.parse('*');
        nodeStream.pipe(jsonStream);
        return jsonStream;
      }
    } else {
      response = await this.request(method, path, options);
      if (response.status >= 200 && response.status <= 299) {
        const nodeStream = new ReadableWebToNodeStream(response.body);
        const jsonStream = JSONStream.parse('*');
        nodeStream.pipe(jsonStream);
        return jsonStream;
      }

      body = await response.json();
    }

    const apiError = new Error();
    apiError.name = 'ApiError';
    apiError.message = body.message || 'Error';
    apiError.status = response.status;
    apiError.responseBody = body;
    apiError.request = { method, path, options };
    throw apiError;
  }

  async getIVCurve(siteCode, options = {}, isSTC = false) {
    const { rawData } = await store.dispatch('settings/getSettings');
    options.query = { ...options.query, raw: rawData };
    if (isSTC) return this.get(`/sites/${siteCode}/iv-stc/curves`, options);
    return this.get(`/sites/${siteCode}/iv/curves`, options);
  }

  async getLatestIVCurve(siteCode, options = {}) {
    const { rawData } = await store.dispatch('settings/getSettings');
    options.query = { ...options.query, raw: rawData };
    if (siteCode.toUpperCase() === 'D-MSD') {
      const from = moment().subtract(5, 'minute').tz('America/Denver').toISOString(true);
      const to = moment().tz('America/Denver').toISOString(true);
      options.query = { ...options.query, from, to };
      const response = await this.getIVCurve(siteCode, options);
      return response.slice(-1)[0];
    }

    return this.get(`/sites/${siteCode}/iv/latest`, options);
  }

  async getWeather(siteCode) {
    let options = {};
    options = await this.requestIntercept(options);
    const response = await fetch(`${process.env.VUE_APP_DAQ_WEATHER_URL}/forecast/${siteCode}`, options);
    const body = response.json();
    if (response.status >= 200 && response.status <= 299) return body;
    const apiError = new Error();
    apiError.name = 'ApiError';
    apiError.message = body.message || 'Error';
    apiError.status = response.status;
    throw apiError;
  }

  install(_Vue) {
    if (Vue && _Vue === Vue) {
      if (process.env.NODE_ENV !== 'production') console.error('[daqApi] already installed. Vue.use(DaqApi) should be called only once.');
      return;
    }

    Vue = _Vue;
    Vue.prototype[`$${this.name}`] = this;
  }
}

export default DaqApiPlugin;
