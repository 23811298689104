import { naturalCompare } from '../../helpers/helpers';

const state = {
  sensorTypes: []
};

const getters = {
  getSensorTypeByUuid: state => uuid => state.sensorTypes.find(sensorType => sensorType.uuid === uuid)
};

const mutations = {
  SET_SENSOR_TYPES(state, sensorTypes) {
    state.sensorTypes = sensorTypes.map(sensorType => ({ ...sensorType, name: `${sensorType.manufacturer} ${sensorType.model}` }));
  }
};

const actions = {
  async setSensorTypes({ commit }) {
    const sensorTypes = await this.$daqApi.get('/sensor-types');
    sensorTypes.sort((a, b) => naturalCompare(a.manufacturer, b.manufacturer) || naturalCompare(a.model, b.model));
    commit('SET_SENSOR_TYPES', sensorTypes);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
