<template>
  <div class="site-multiselect">
    <b-form-group label="Site" class="form-label">
      <multiselect
        :value="selectedSite"
        @input="handleInput"
        :options="sites"
        track-by="name"
        label="name"
        :searchable="!$feature.mobile"
        selectLabel=""
        deselectLabel=""
        :allowEmpty="false"
        placeholder="Select Site"
        :loading="loading">
      </multiselect>
    </b-form-group>

    <b-modal title="Switch Site?" hide-footer v-model="switchSiteWarning">
      <div>
        You are about to navigate to the <b>{{ switchSite && switchSite.name }}</b> site and will lose all your plotted graphs.
        Are you sure you want to switch the site?
      </div>
      <div class="d-flex justify-content-end pt-2">
        <b-button variant="primary" size="sm" class="mr-2" @click="handleSwitchSite">Switch Site</b-button>
        <b-button variant="secondary" size="sm" @click="cancelSwitchSite">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { get, call } from 'vuex-pathify';
import Multiselect from 'vue-multiselect';
import { BFormGroup, BModal, BButton } from 'bootstrap-vue';

export default {
  name: 'SiteDropdown',
  components: {
    Multiselect,
    BFormGroup,
    BModal,
    BButton
  },
  props: {
    loading: Boolean
  },
  data() {
    return {
      switchSite: null,
      switchSiteWarning: false
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    selectedSiteActiveAnalysis: get('sites/selectedSiteActiveAnalysis'),
    sites: get('sites/getSites')
  },
  methods: {
    saveToLocalStorage: call('localstorage/saveToLocalStorage'),
    removeFromLocalStorage: call('localstorage/removeFromLocalStorage'),
    getFromLocalStorage: call('localstorage/getFromLocalStorage'),
    setSelectedSite(site) {
      this.$store.commit('sites/SET_SELECTED_SITE', site);
      if (site) this.saveToLocalStorage({ key: 'site', value: this.selectedSite });
      else this.removeFromLocalStorage({ key: 'site' });
    },
    handleInput(site) {
      if (this.selectedSiteActiveAnalysis) {
        this.switchSite = site;
        this.switchSiteWarning = true;
      } else {
        this.setSelectedSite(site);
      }
    },
    handleSwitchSite() {
      this.setSelectedSite(this.switchSite);
      this.switchSiteWarning = false;
    },
    cancelSwitchSite() {
      this.switchSiteWarning = false;
    }
  },
  watch: {
    loading: {
      immediate: true,
      async handler() {
        if (this.loading) return;
        const lastSelectedSite = await this.getFromLocalStorage({ key: 'site' });
        const found = lastSelectedSite && this.sites.find(({ id }) => id === lastSelectedSite.id);
        if (found) this.setSelectedSite(found);
        else this.setSelectedSite(this.sites[0] || null);
      }
    }
  }
};
</script>

<style scoped>
  .form-label {
    font-weight: 600;
    font-size: 0.875rem;
    color: #55595c;
    margin-bottom: 0px;
  }
</style>

<style lang="scss">
  .site-multiselect .multiselect .multiselect__option--highlight {
    background: $msi-orange;
  }

  .site-multiselect .multiselect .multiselect__option--selected.multiselect__option--highlight {
    background: #FF6A6A
  }

  .site-multiselect .multiselect .multiselect__tags .multiselect__single {
    font-size: 12px;
  }

  .site-multiselect .multiselect {
    font-size: 12px;
  }

  .site-multiselect .multiselect__spinner {
    margin-top: 2px;
  }

  .site-multiselect .multiselect__spinner::before {
    border-top-color: $msi-orange;
  }

  .site-multiselect .multiselect__spinner::after {
    border-top-color: $msi-orange;
  }

  .site-multiselect .multiselect__input {
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  .site-multiselect .multiselect .multiselect__tags .multiselect__input {
    width: 75% !important;
  }

  .sidebar-minimized .sidebar .site-multiselect {
    display: none;
  }
</style>
