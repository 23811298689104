<template>
  <div>
    <b-form-group
      label="IV Curve Raw Data"
      :description="rawDataDescription">
      <b-form-checkbox v-model="settings.rawData" switch>{{ settings.rawData ? 'ON' : 'OFF' }}</b-form-checkbox>
    </b-form-group>

    <div class="d-flex justify-content-end">
      <b-button size="sm" variant="primary" @click="handleSave">Save</b-button>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormCheckbox, BButton } from 'bootstrap-vue';
import { call } from 'vuex-pathify';

export default {
  name: 'Settings',
  components: {
    BFormGroup,
    BFormCheckbox,
    BButton
  },
  data() {
    return {
      settings: {
        rawData: true
      }
    };
  },
  computed: {
    rawDataDescription() {
      if (this.settings.rawData) return 'This option will show you all collected points from the DAQ for IV curves.';
      return 'This option will remove noise from the IV curve and result in a smoother curve.';
    }
  },
  methods: {
    getSettings: call('settings/getSettings'),
    saveSettings: call('settings/saveSettings'),
    handleSave() {
      this.saveSettings(this.settings);
      this.$emit('settingsClosed');
    }
  },
  async mounted() {
    const settings = await this.getSettings();
    if (settings) {
      this.settings = settings;
    }
  }
};
</script>
