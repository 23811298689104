import { make } from 'vuex-pathify';

const state = {
  metrics: []
};

const getters = {
  getSiteMetrics: state => (siteCode) => {
    return state.metrics.filter(metric => metric.siteCode === siteCode);
  }
};

const mutations = make.mutations(state);

const actions = {
  async setMetrics({ commit, rootGetters }) {
    const sites = rootGetters['sites/getSites'];
    const metrics = (await Promise.all(sites.map(site => this.$daqApi.get(`/sites/${site.id}/metrics`)))).flat();
    commit('SET_METRICS', metrics);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
