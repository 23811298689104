import { event } from 'vue-gtag';
import categories from './actions';

let Vue;

export default class gtagPlugin {
  constructor(kauth) {
    this.kauth = kauth;
  }

  makeEvents(baseOpts) {
    /** Maps all categories and actions into functions for ease of use of vue-gtag
     * @param {Object} baseOpts: Contains default options to pass into every gtag event.
     * E.g.: { Category: ['action1', 'action2'] } wil be mapped to:
     * {
     *  Category: [
     *    action1: (opts) => event('action1', { event_category: 'Category', ...baseOpts, ...opts}),
     *    action2: (opts) => event('action2', { event_category: 'Category', ...baseOpts, ...opts})
     *  ]
     * }
     * Then, these functions can be used as:
     * gtagPlugin.events.Category.action1(opts)
     * Where @param {Object} opts contains options to pass into the event (E.g.: event_label, value, ...)
     */
    this.events = Object.entries(categories).reduce((acc, [category, actions]) => {
      const actionFunctions = actions.reduce((actns, action) => ({
        ...actns,
        [action]: opts => event(action, { event_category: category, ...baseOpts, ...opts })
      }), {});

      return { ...acc, [category]: actionFunctions, };
    }, {});
  }

  async setup() {
    await this.kauth.getValidToken();
    const baseOpts = { msiUser: this.kauth.idTokenParsed.sub };
    this.makeEvents(baseOpts);
  }

  install(_Vue) {
    if (Vue && _Vue === Vue) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(
          '[GtagPlugin] already installed. Vue.use(GtagPlugin) should be called only once.'
        );
      }
      return;
    }
    Vue = _Vue;
    Vue.prototype.$gtagPlugin = this;
  }
}
